import _ from "lodash"
import XXH from "xxhashjs"

export const EXTERNAL_WORKSPACE_PREFIX = "ext$"

export const externalFileIdPrefix = (originalWorkspaceId, newWorkspaceId) =>
	`ext$${originalWorkspaceId}$${newWorkspaceId}$`

export const getEnvVar = (key, { required = true, json = false } = {}) => {
	let value = process.env[key]?.trim()
	if (required && !value) {
		throw Error(`environment variable ${key} missing`)
	}
	if (value && json) {
		try {
			value = JSON.parse(value)
		} catch (e) {
			throw Error(`environment variable ${key} is not valid JSON`, e)
		}
	}
	return value
}
const FILES_DOMAINS =
	getEnvVar("CONDENS_FILES_DOMAINS", { required: false, json: true }) ??
	getEnvVar("VUE_APP_FILES_DOMAINS", { required: false, json: true })
export const domainForWorkspaceId = workspaceOrOrgId => {
	if (!FILES_DOMAINS) {
		throw Error("CONDENS_FILES_DOMAINS or VUE_APP_FILES_DOMAINS need to be set, but they arent")
	}
	const CLOUD_FRONT_ONLY_ORGS = ["pwc_labs", "ksb", "mete_inc_charts", "sennheiser_electronic_se__co_k"]
	const cloudFrontDomain =
		process.env.CONDENS_FILES_CLOUDFRONT_DOMAIN || process.env.VUE_APP_CONDENS_FILES_CLOUDFRONT_DOMAIN
	if (
		cloudFrontDomain &&
		CLOUD_FRONT_ONLY_ORGS.some(
			o =>
				workspaceOrOrgId?.startsWith(o) &&
				(workspaceOrOrgId === o || workspaceOrOrgId.startsWith(`${o}#`) || workspaceOrOrgId.startsWith(`${o}%23`))
		)
	) {
		return cloudFrontDomain
	}
	if (workspaceOrOrgId === "public_files") {
		return "files2.condens.io"
	}
	const domain = FILES_DOMAINS[regionFromWorkspaceId(workspaceOrOrgId)]
	if (domain == null) {
		throw Error(`Domain not found for workspaceOrOrgId=${workspaceOrOrgId}, domains=${JSON.stringify(FILES_DOMAINS)}`)
	}
	return domain
}

export const encodeCondensFilePathForUrl = path => {
	if (path.startsWith("/")) {
		path = path.substring(1)
	}
	if (path.startsWith("w/") || path.startsWith("g/")) {
		const split = path.split("/")
		if (split.length > 1) {
			split[1] = encodeURIComponent(split[1])
		}
		path = split.join("/")
	}
	return path
}

export const urlForCondensFile = (path, workspaceOrOrgId, accessToken = null) => {
	path = encodeCondensFilePathForUrl(path)
	let url = `https://${domainForWorkspaceId(workspaceOrOrgId)}/${path}`
	if (accessToken != null) {
		url += "?a=" + accessToken
	}
	return url
}

export const pathForWorkspaceFile = (file, folder = "w", suffix = "") => {
	// We have the implicit assumption that file only needs id and workspaceId, externallyStoredIn is ofc optional.
	// If we ever change this, we need to make sure we don't break anything, for example MediaPlayer -> virtualAudioFileId
	const storedIn = _.pick(file.externallyStoredIn ?? file, "id", "workspaceId")
	return `${folder}/${storedIn.workspaceId}/${storedIn.id}${suffix}`
}

export const urlForWorkspaceFile = (file, accessToken, folder = "w", suffix = "") =>
	urlForCondensFile(
		pathForWorkspaceFile(file, folder, suffix),
		file.externallyStoredIn?.workspaceId ?? file.workspaceId,
		accessToken
	)

export const ITEM_ID_LENGTH = 20
const ITEM_ID_CONTENT_REG_EXP = /^[a-zA-Z0-9]+$/
export const isItemId = (s, length = ITEM_ID_LENGTH) => {
	if (!_.isString(s) || s.length !== length) {
		return false
	}
	return ITEM_ID_CONTENT_REG_EXP.test(s) === true
}
const ID_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
export const createRandomFirestoreIdGenerator = ({ randomFunc = null, alphabet = ID_CHARS } = {}) => {
	if (randomFunc == null && (typeof globalThis === "undefined" || globalThis.crypto == null)) {
		randomFunc = Math.random
	}
	if (randomFunc != null) {
		return (length = ITEM_ID_LENGTH) => {
			let result = ""
			for (let i = 0; i < length; ++i) {
				result += alphabet.charAt(Math.floor(randomFunc() * alphabet.length))
			}
			return result
		}
	}
	return (length = ITEM_ID_LENGTH) => {
		const values = new Uint8Array(length)
		globalThis.crypto.getRandomValues(values)
		let result = ""
		for (const v of values) {
			result += alphabet.charAt(v % alphabet.length)
		}
		return result
	}
}

export const randomFirestoreId = createRandomFirestoreIdGenerator()

export const extractOrgId = workspaceId => (_.isString(workspaceId) ? workspaceId.split("#")[0] : null)

export const hasAccessToWorkspace = (workspaceId, { orgId, role, workspaceIds }) => {
	if (extractOrgId(workspaceId) !== orgId) {
		return false
	}
	return role === "admin" || (workspaceIds ?? []).includes(workspaceId)
}

export const validIntegerOrZero = v => {
	if (_.isString(v)) {
		v = parseInt(v)
	}
	if (!_.isInteger(v)) {
		return 0
	}
	return _.isNaN(v) ? 0 : v
}

export const firstLetterUppercase = t => {
	if (!t) {
		return t
	}
	return t.charAt(0).toUpperCase() + t.substring(1)
}

export const parseSeconds = s => {
	const hours = Math.floor(s / 60 / 60)
	const minutes = Math.floor(s / 60) % 60
	const seconds = Math.floor(s) % 60
	const tenths = Math.floor(s * 10) % 10
	const milliseconds = Math.floor(s * 1000) % 1000
	return { hours, minutes, seconds, tenths, milliseconds }
}

export const secondsToParsedString = s => {
	const parsed = parseSeconds(s)
	return {
		hours: String(parsed.hours),
		minutes: String(parsed.minutes).padStart(2, "0"),
		seconds: String(parsed.seconds).padStart(2, "0"),
		tenths: String(parsed.tenths),
	}
}

export const secondsToStringWithUnits = (s, includeTenths = false) => {
	const parsed = parseSeconds(s)
	const secondsText = includeTenths ? `${parsed.seconds}.${parsed.tenths}` : `${parsed.seconds}`
	let result = `${secondsText} seconds`
	if (parsed.minutes > 0) {
		result = `${parsed.minutes} minutes ${result}`
	}
	if (parsed.hours > 0) {
		result = `${parsed.hours} hours ${result}`
	}
	return result
}

export const secondsToString = (s, includeTenths = false) => {
	const parsed = secondsToParsedString(s)
	let result = `${parsed.minutes}:${parsed.seconds}`
	if (includeTenths) {
		result += `.${parsed.tenths}`
	}
	if (parsed.hours > 0) {
		result = `${parsed.hours}:${result}`
	}
	return result
}

export const highlightReelDurationText = (playablesCount, totalDuration, displayName = "Highlight") =>
	`${playablesCount} ${displayName}${playablesCount > 1 ? "s" : ""} - ${secondsToString(totalDuration)}`

export const secondsToTimestampString = (s, { includeMillisecondsWithSeparator = null } = {}) => {
	const { hours, minutes, seconds, milliseconds } = parseSeconds(s)
	const format = t => String(t).padStart(2, "0")
	const millisecondsString =
		includeMillisecondsWithSeparator != null
			? `${includeMillisecondsWithSeparator}${String(milliseconds).padStart(3, "0")}`
			: ""
	return `${format(hours)}:${format(minutes)}:${format(seconds)}${millisecondsString}`
}

export const fileNameForExport = file => {
	const name = file.name || "Unnamed file"
	const dotIndex = name.lastIndexOf(".")
	const suffix = `-${file.id.substring(0, 5)}`
	if (dotIndex === -1) {
		return name + suffix
	}
	return name.substring(0, dotIndex) + suffix + name.substring(dotIndex)
}

const iterateProseMirrorJsonInner = (node, cb) => {
	cb(node)
	if (_.isArray(node.content)) {
		node.content.forEach(node => iterateProseMirrorJsonInner(node, cb))
	}
}

export const iterateProseMirrorJson = (nodeOrArray, cb) => {
	if (nodeOrArray == null) {
		return
	}
	if (!_.isArray(nodeOrArray)) {
		nodeOrArray = [nodeOrArray]
	}
	nodeOrArray.forEach(node => iterateProseMirrorJsonInner(node, cb))
}

export const PRETTY_COLORS_ORDER = [
	"lightblue",
	"turquoise",
	"red",
	"yellow",
	"blue",
	"green",
	"pink",
	"orange",
	"gray",
	"greenblue",
	"purple",
	"brown",
]
export const PRETTY_COLORS_ORDER_HORIZONTAL = [
	"lightblue",
	"blue",
	"purple",
	"pink",
	"red",
	"orange",
	"yellow",
	"brown",
	"green",
	"greenblue",
	"turquoise",
	"gray",
]

export const isParticipantId = speakerId => speakerId.length === 20
export const USER_ID_LENGTH = 28
export const isUserId = speakerId => speakerId.length === USER_ID_LENGTH

const mapValuesDeepInternal = (obj, cb, key, stopDescendCb) => {
	if (!stopDescendCb(obj)) {
		if (_.isArray(obj)) {
			return obj.map((i, index) => mapValuesDeepInternal(i, cb, index, stopDescendCb))
		}
		if (_.isObject(obj)) {
			if (!_.isPlainObject(obj)) {
				return cb(obj, key)
			}
			return _.mapValues(obj, (v, k) => mapValuesDeepInternal(v, cb, k, stopDescendCb))
		}
	}
	return cb(obj, key)
}
export const mapValuesDeep = (obj, cb, stopDescendCb = () => false) =>
	mapValuesDeepInternal(obj, cb, null, stopDescendCb)

export let REGION = process.env.CONDENS_REGION || process.env.VUE_APP_CONDENS_REGION
export const setRegion = r => (REGION = r)

const determineServerRegions = () => {
	const ENV_VARS = [
		"CONDENS_FUNCTIONS_SERVER_URLS",
		"CONDENS_BACKEND_SERVER_URLS",
		"VUE_APP_FUNCTIONS_SERVER_URLS",
		"VUE_APP_BACKEND_SERVER_URLS",
	]
	for (const envVar of ENV_VARS) {
		const value = getEnvVar(envVar, { required: false, json: true })
		if (value != null) {
			return Object.keys(value)
		}
	}
	if (REGION != null) {
		return [REGION]
	}
	return []
}
export const SERVER_REGIONS = determineServerRegions()
if (REGION && !SERVER_REGIONS) {
	throw Error(`REGION=${REGION} not part of SERVER_REGIONS=${SERVER_REGIONS}`)
}

export const NON_EU_SERVER_REGIONS = SERVER_REGIONS.filter(s => s !== "eu")
const NON_EU_SERVER_REGIONS_CHARS = NON_EU_SERVER_REGIONS.map(r => r.substring(0, 1))
const REGEX_SERVER_REGION_PART =
	NON_EU_SERVER_REGIONS_CHARS.length > 0 ? `[${NON_EU_SERVER_REGIONS_CHARS.join("")}]?` : ""

export const regionFromWorkspaceId = workspaceId => {
	const region = NON_EU_SERVER_REGIONS.find(r => workspaceId?.startsWith(`${r}__`))
	return region ?? "eu"
}

export const pickServiceUrl = (serviceUrls, workspaceOrOrgId) => serviceUrls[regionFromWorkspaceId(workspaceOrOrgId)]

export const ORG_ID_HASH_BASE_LENGTH = 6

let xxhash = null
const getXxhash = () => {
	if (xxhash == null) {
		xxhash = XXH.h32()
	}
	return xxhash
}

export const hashString = s => getXxhash().update(s).digest().toString(16)

let xxhash64 = null
const getXxhash64 = () => {
	if (xxhash64 == null) {
		xxhash64 = XXH.h64()
	}
	return xxhash64
}

export const hashString64 = s => getXxhash64().update(s).digest().toString(16)

export const maybeAppendRegionCharToId = (id, region) => {
	region ??= REGION
	if (region != null && region !== "eu") {
		id += region.charAt(0)
	}
	return id
}

export const hashWorkspaceId = (workspaceId, orgIdHashLength = ORG_ID_HASH_BASE_LENGTH) => {
	if (workspaceId == null) {
		return null
	}
	const orgId = extractOrgId(workspaceId)
	let hashNumber = getXxhash().update(orgId).digest().toNumber()
	const CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	let result = ""
	const baseLenght = orgIdHashLength < ORG_ID_HASH_BASE_LENGTH ? OLD_ORG_ID_HASH_LENGTH : ORG_ID_HASH_BASE_LENGTH
	while (result.length < baseLenght) {
		result += CHARS[hashNumber % CHARS.length]
		hashNumber = Math.floor(hashNumber / CHARS.length)
	}
	result = maybeAppendRegionCharToId(result, regionFromWorkspaceId(workspaceId))
	result += workspaceId.substring(orgId.length + 1)
	return result
}

const OLD_ORG_ID_HASH_LENGTH = 3
export const oldHashWorkspaceId = workspaceId => hashWorkspaceId(workspaceId, OLD_ORG_ID_HASH_LENGTH)

export const orgIdHashFromWorkspaceIdHash = workspaceIdHash => {
	if (workspaceIdHash.length < ORG_ID_HASH_BASE_LENGTH) {
		return workspaceIdHash.substring(0, OLD_ORG_ID_HASH_LENGTH)
	}
	let length = ORG_ID_HASH_BASE_LENGTH
	if (
		workspaceIdHash.length > ORG_ID_HASH_BASE_LENGTH &&
		NON_EU_SERVER_REGIONS_CHARS.some(c => workspaceIdHash[ORG_ID_HASH_BASE_LENGTH] === c)
	) {
		length = ORG_ID_HASH_BASE_LENGTH + 1
	}
	return workspaceIdHash.substring(0, length)
}

export const workspaceIdFromHashed = (hash, orgId) => {
	const orgIdHash = hash.length < ORG_ID_HASH_BASE_LENGTH ? oldHashWorkspaceId(orgId) : hashWorkspaceId(orgId)
	if (hash.substring(0, orgIdHash.length) !== orgIdHash) {
		return null
	}
	if (orgIdHash === hash) {
		return orgId
	}
	return `${orgId}#${hash.substring(orgIdHash.length)}`
}

// this has to start and end with brackets and use no other brackets to work for matching parameters in vue router
export const WORKSPACE_ID_HASH_REGEX_STRING = `([a-zA-Z0-9]{${OLD_ORG_ID_HASH_LENGTH}}[0-9]*|[a-zA-Z0-9]{${ORG_ID_HASH_BASE_LENGTH}}${REGEX_SERVER_REGION_PART}[0-9]*|___|______)`

export const isWorkspaceIdHashPlaceholder = s => s === "___" || s === "______"

export const workspaceIdHashWholeStringRegex = new RegExp("^" + WORKSPACE_ID_HASH_REGEX_STRING + "$")

export const getWorkspaceIdHashFromPath = path => {
	const pathName = new URL("https://app.condens.io" + path).pathname
	let workspaceIdHash = pathName.split("/").filter(i => i.length > 0)[1]
	if (workspaceIdHash == null || isWorkspaceIdHashPlaceholder(workspaceIdHash)) {
		return null
	}
	const regex = workspaceIdHashWholeStringRegex
	const matches = workspaceIdHash.match(regex)
	if (matches == null || matches[0] !== workspaceIdHash) {
		return null
	}
	return workspaceIdHash
}

const regionFromSingleChar = c => {
	if (!c) {
		return "eu"
	}
	return NON_EU_SERVER_REGIONS.find(r => r.startsWith(c)) ?? "eu"
}

export const regionFromWorkspaceIdHash = hash => {
	if (!_.isString(hash)) {
		return null
	}
	if (hash.length <= ORG_ID_HASH_BASE_LENGTH) {
		return "eu"
	}
	const c = hash[ORG_ID_HASH_BASE_LENGTH]
	if (/^\d$/.test(c)) {
		return "eu"
	}
	return regionFromSingleChar(c)
}

export const generateSortValuesBetween = (from, to, valuesCount) => {
	if (valuesCount <= 0) {
		return []
	}
	let step = (to - from) / (valuesCount + 1)
	if (step === 0) {
		step = 0.1 / (valuesCount + 1) // to make it more robust
	}
	const splitEvenly = _.range(valuesCount).map(i => from + (i + 1) * step)
	const MAX_DEVATION = step * 0.2
	return splitEvenly.map(r => r + (Math.random() - 0.5) * MAX_DEVATION)
}

const LEAF_MERGE_OPS = ["delete", "now", "version"]
const NON_LEAF_MERGE_OPS = ["set", "with", "without", "sum"]
const VALID_MERGE_OPS = [...NON_LEAF_MERGE_OPS, ...LEAF_MERGE_OPS]
const MERGE_OP_KEY = "__MERGE_OP__"

const iterateMergeOps = (obj, cb) => {
	if (obj == null) {
		return obj
	}
	const type = obj[MERGE_OP_KEY]
	if (type != null) {
		if (LEAF_MERGE_OPS.includes(type)) {
			return cb(obj, type)
		} else {
			return cb({ [MERGE_OP_KEY]: type, value: iterateMergeOps(obj.value, cb) }, type)
		}
	}
	if (_.isArray(obj)) {
		return obj.map(v => iterateMergeOps(v, cb)).filter(i => i !== undefined)
	}
	if (_.isPlainObject(obj)) {
		return _.pickBy(
			_.mapValues(obj, v => iterateMergeOps(v, cb)),
			v => v !== undefined
		)
	}
	return obj
}

const resolveLeafMergeOp = (mergeOp, version, value) => {
	if (mergeOp === "now") {
		return Date.now() + (value ?? 0)
	}
	if (mergeOp === "version") {
		return version ?? 1e13
	}
	if (mergeOp === "delete") {
		return undefined
	}
	return mergeOp
}

export const resolveAllMergeOps = (obj, version) =>
	iterateMergeOps(obj, (v, mergeOp) => {
		if (mergeOp === "without") {
			return []
		}
		if (mergeOp === "with") {
			return _.isArray(v.value) ? v.value : [v.value]
		}
		if (mergeOp === "set") {
			return v.value
		}
		if (mergeOp === "sum") {
			return v.value
		}
		return resolveLeafMergeOp(mergeOp, version, v.value)
	})

const ensureIsResolvedArray = (value, version) => {
	let values = _.isArray(value) ? value : [value]
	return values.map(v => resolveAllMergeOps(v, version))
}

const mergeInChangesInternal = (item, changes, version) => {
	for (const [key, value] of Object.entries(changes)) {
		if (!_.isObject(value)) {
			_.set(item, key, value)
			continue
		}
		const mergeOp = value[MERGE_OP_KEY]
		if (mergeOp == null || !VALID_MERGE_OPS.includes(mergeOp)) {
			let currentPropValue = _.get(item, key)
			if (!_.isPlainObject(currentPropValue)) {
				_.set(item, key, resolveAllMergeOps(value, version))
			} else {
				_.set(item, key, mergeInChangesInternal(currentPropValue, value, version))
			}
		} else if (mergeOp === "set") {
			_.set(item, key, resolveAllMergeOps(value.value, version))
		} else if (mergeOp === "with") {
			let currentPropValue = _.get(item, key)
			const values = ensureIsResolvedArray(value.value, version)
			let updatedValues = values
			if (_.isArray(currentPropValue)) {
				if (value.insertIndex == null) {
					updatedValues = [...currentPropValue, ...values.filter(v => !currentPropValue.some(w => _.isEqual(v, w)))]
				} else if (!_.isArray(value.insertIndex)) {
					const filtered = currentPropValue.filter(v => !values.some(w => _.isEqual(v, w)))
					updatedValues = [...filtered.slice(0, value.insertIndex), ...values, ...filtered.slice(value.insertIndex)]
				} else {
					updatedValues = currentPropValue.filter(v => !values.some(w => _.isEqual(v, w)))
					for (let [index, v] of _.zip(value.insertIndex.slice(0, values.length), values)) {
						index = index ?? updatedValues.length
						updatedValues = [...updatedValues.slice(0, index), v, ...updatedValues.slice(index)]
					}
				}
			}
			_.set(item, key, updatedValues)
		} else if (mergeOp === "without") {
			let currentPropValue = _.get(item, key)
			if (!_.isArray(currentPropValue)) {
				_.set(item, key, [])
			} else {
				const values = ensureIsResolvedArray(value.value, version)
				_.set(
					item,
					key,
					currentPropValue.filter(v => !values.some(w => _.isEqual(v, w)))
				)
			}
		} else if (mergeOp === "delete") {
			_.unset(item, key)
		} else if (mergeOp === "sum") {
			let currentPropValue = _.get(item, key)
			_.set(item, key, value.value + (_.isFinite(currentPropValue) ? currentPropValue : 0))
		} else {
			_.set(item, key, resolveLeafMergeOp(mergeOp, version, value.value))
		}
	}
	return item
}

const MERGE_OPS_WITH_ATTRS = [...NON_LEAF_MERGE_OPS, "now"]

export const mergeOp = (op, value = null, additionalAttrs = undefined) => {
	if (!VALID_MERGE_OPS.includes(op)) {
		throw Error(`Unknown merge op "${op}"`)
	}
	const result = { [MERGE_OP_KEY]: op }
	if (MERGE_OPS_WITH_ATTRS.includes(op)) {
		result.value = value
		if (additionalAttrs !== undefined) {
			Object.assign(result, additionalAttrs)
		}
	}
	return result
}

export const getMergeOpType = value => {
	const type = value?.[MERGE_OP_KEY]
	return VALID_MERGE_OPS.includes(type) ? type : null
}

export const mergeInChanges = (item, changes, version = undefined) => {
	if (changes == null || (_.isObject(changes) && _.isEmpty(changes))) {
		return item
	}
	if (!_.isPlainObject(item) || getMergeOpType(changes) != null) {
		return mergeInChanges({ value: item }, { value: changes }, version).value
	}
	return mergeInChangesInternal(_.cloneDeep(item), changes, version)
}

export const inverseMergeOpsForUpdate = (attrs, update) => {
	const attrsAfter = mergeInChanges(attrs, update)
	const inverseMergeOps = {}
	for (const key of Object.keys(update)) {
		if (_.isEqual(_.get(attrsAfter, key), _.get(attrs, key))) {
			continue
		}
		if (_.has(attrsAfter, key) && !_.has(attrs, key)) {
			inverseMergeOps[key] = mergeOp("delete")
			continue
		}
		const updateValue = _.get(update, key)
		const valueBefore = _.get(attrs, key)
		if (getMergeOpType(updateValue) === "with") {
			let values = updateValue.value
			if (!_.isArray(values)) {
				values = [values]
			}
			if (
				Array.isArray(valueBefore) &&
				_.isEqual(resolveAllMergeOps(values, 0), values) &&
				!values.some(v => valueBefore.some(vB => _.isEqual(v, vB)))
			) {
				inverseMergeOps[key] = mergeOp("without", values)
				continue
			}
		}
		if (getMergeOpType(updateValue) === "without") {
			let values = updateValue.value
			if (!_.isArray(values)) {
				values = [values]
			}
			if (Array.isArray(valueBefore) && _.isEqual(resolveAllMergeOps(values, 0), values)) {
				const valuesWithIndex = values
					.map(value => ({ value, index: valueBefore.findIndex(v => _.isEqual(v, value)) }))
					.filter(i => i.index !== -1)
				const sortedValuesWithIndex = _.sortBy(valuesWithIndex, "index")
				inverseMergeOps[key] = mergeOp(
					"with",
					sortedValuesWithIndex.map(i => i.value),
					{ insertIndex: sortedValuesWithIndex.map(i => i.index) }
				)
				continue
			}
		}
		inverseMergeOps[key] = mergeOp("set", valueBefore)
	}
	return inverseMergeOps
}

export const isVersionAfterSnapshot = (version, snapshot) =>
	version >= snapshot.xmax || snapshot.xipList.includes(version)

const matchCount = (regex, text) => {
	const matches = text.match(regex)
	if (matches == null) {
		return 0
	}
	return matches.length
}

const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g

const escapeStringRegexp = str => {
	if (typeof str !== "string") {
		throw new TypeError("Expected a string")
	}
	return str.replace(matchOperatorsRe, "\\$&")
}

export const createSearchTermRegExps = searchTerms => {
	const escapedSearchTerms = searchTerms.map(escapeStringRegexp)
	const anywhere = escapedSearchTerms.map(t => new RegExp(t, "gi"))
	const word = escapedSearchTerms.map(t => new RegExp(`\\b${t}\\b`, "gi"))
	return { anywhere, word }
}

export const calcMatchCounts = (prioTexts, searchTermsRegExps) => {
	const matchCounts = []
	for (const text of prioTexts) {
		const word = searchTermsRegExps.word.map(r => matchCount(r, text))
		const anywhere = searchTermsRegExps.anywhere.map(r => matchCount(r, text))
		matchCounts.push({ word, anywhere })
	}
	return matchCounts
}

export const countMatchesInPrioTexts = (itemsWithPrioTexts, searchTerms) => {
	const searchTermsRegExps = createSearchTermRegExps(searchTerms)
	const result = []
	for (const item of itemsWithPrioTexts) {
		result.push({ ...item, matchCounts: calcMatchCounts(item.prioTexts, searchTermsRegExps) })
	}
	return result
}

export const mergeMatchCounts = (matchCounts1, matchCounts2) => {
	const mergeMatchCount = (count1, count2) => _.zip(count1, count2).map(([i, j]) => (i ?? 0) + (j ?? 0))
	const result = []
	for (let [count1, count2] of _.zip(matchCounts1, matchCounts2)) {
		result.push({
			word: mergeMatchCount(count1?.word ?? [], count2?.word ?? []),
			anywhere: mergeMatchCount(count1?.anywhere ?? [], count2?.anywhere ?? []),
		})
	}
	return result
}

export const mergeSearchScores = (score1, score2) => {
	const length = Math.max(score1.length, score2.length)
	const result = Array(length)
	for (let i = 0; i < length; i += 1) {
		result[i] = Math.max(score1[i] ?? 0, score2[i] ?? 0)
	}
	return result
}

export const numberOfMatchesForArtifactSearchScore = score => {
	let numberOfMatches = 0
	// we start at 1 and not 0 because for artifacts the title is part of the content
	// as well, because its part of the prosemirror doc
	for (let i = 1; i < 2; i += 1) {
		numberOfMatches += score[i * 4 + 3] ?? 0
	}
	return numberOfMatches
}

export const scoreToNaturalSortString = score => score.map(v => String(v)).join(".")

export const DEBUG_USER_ID_PREFIX = "yhAM8SWPBA"
export const isDebugUserId = id => id?.startsWith(DEBUG_USER_ID_PREFIX)

export const normalizeWhitespace = s => {
	if (s == null) {
		return null
	}
	return s.trim().replace(/\s\s+/g, " ")
}

export const ensureObservationSectionNameValid = (origName, otherSectionNames) => {
	let i = 1
	origName = normalizeWhitespace(origName)
	for (;;) {
		const name = i === 1 ? origName : `${origName} ${i}`
		if (!otherSectionNames.includes(name)) {
			return name
		}
		i += 1
	}
}

const isBrowser = typeof window !== "undefined" && typeof window.document !== "undefined"

const ensureDoesntEndWithSlash = url => {
	if (url[url.length - 1] === "/") {
		return url.substring(0, url.length - 1)
	}
	return url
}

export const repoUrl = () => {
	if (!isBrowser) {
		return process.env.CONDENS_REPO_URL ?? "https://repo.condens.io"
	}
	const url = new URL(window.location.href)
	url.search = ""
	if (url.host.startsWith("repo.")) {
		url.pathname = ""
	} else if (url.host.startsWith("app.")) {
		url.host = "repo." + url.host.substring("app.".length)
		url.pathname = ""
	} else if (url.host === "condens-de.firebaseapp.com") {
		url.host = "repo.condens.io"
		url.pathname = ""
	} else {
		url.pathname = `/repo`
	}
	return ensureDoesntEndWithSlash(url.href)
}

export const appUrl = () => {
	if (!isBrowser) {
		return process.env.CONDENS_APP_URL ?? "https://app.condens.io"
	}
	const url = new URL(window.location.href)
	url.search = ""
	url.pathname = ""
	if (["localhost", "local.condens.io"].includes(url.hostname)) {
		url.pathname = "/app"
	} else if (url.host.startsWith("repo.")) {
		url.host = "app." + url.host.substring("repo.".length)
	} else if (url.host === "condens-de.firebaseapp.com") {
		url.host = "app.condens.io"
		url.pathname = ""
	}
	let result = url.href
	if (result[result.length - 1] === "/") {
		result = result.substring(0, result.length - 1)
	}
	return ensureDoesntEndWithSlash(url.href)
}

export const repoArtifactUrl = (artifactId, workspaceId) =>
	`${repoUrl()}/artifact/${hashWorkspaceId(workspaceId)}/${artifactId}`

export const appArtifactUrl = (artifactId, projectId, workspaceId) =>
	`${appUrl()}/artifact/${hashWorkspaceId(workspaceId)}/${projectId}/${artifactId}`

export const MENTION_REG_EXP = /@[a-zA-Z0-9]{20,28}/gi
export const userIdsMentionedInCommentMessage = text => {
	MENTION_REG_EXP.lastIndex = 0
	const result = []
	let match = null
	for (;;) {
		match = MENTION_REG_EXP.exec(text)
		if (!match) {
			break
		}
		result.push(match[0].substring(1))
	}
	return result
}

export const USER_PERMISSION_PROPS = ["role", "workspaceIds", "active", "orgId"]

export const STORED_QUERIES_COLLECTIONS_ORDER = [
	"projects",
	"tagGroups",
	"tags",
	"artifacts",
	"participants",
	"researchSessions",
	"highlights",
]

export const filtersToDNF = (filters, rootFilter = null) => {
	let andGroup = null
	let dnf = []
	for (const filter of filters) {
		if (filter == null) {
			continue
		}
		if (filter.data?.orConjunction && andGroup != null) {
			dnf.push(andGroup)
			andGroup = []
		}
		if (andGroup == null) {
			andGroup = []
		}
		andGroup.push(filter)
	}
	if (andGroup != null) {
		dnf.push(andGroup)
	}
	if (rootFilter != null) {
		if (dnf.length === 0) {
			dnf = [[rootFilter]]
		} else {
			dnf = dnf.map(andGroup => [rootFilter, ...andGroup])
		}
	}
	return dnf
}

export const singularNameForCollection = collection => {
	if (collection === "researchSessions") {
		return "Session"
	}
	return _.snakeCase(collection.substring(0, collection.length - 1))
		.split("_")
		.map(v => firstLetterUppercase(v))
		.join(" ")
}

export const pluralNameForCollection = collection => {
	if (collection === "everything") {
		return "Everything"
	}
	return `${singularNameForCollection(collection)}s`
}

export const getMostUsedEntry = entries => {
	if ((entries ?? []).length === 0) {
		return null
	}
	return _.maxBy(Object.entries(_.countBy(entries)), _.last)[0]
}

export const applyOperationsToItems = (items, itemOperations) => {
	items = _.uniqBy(items, "id")
	for (const operation of itemOperations) {
		if (operation.type === "update") {
			const index = items.findIndex(i => i.id === operation.item.id)
			if (index !== -1) {
				const updated = mergeInChanges(items[index], operation.item)
				if (updated.deleteVersion != null) {
					delete items[index]
				} else {
					items[index] = updated
				}
			}
		} else if (operation.type === "create") {
			items.push(mergeInChanges({}, operation.item))
		} else {
			throw `Unknown operation type ${operation.type}`
		}
	}
	return items
}

export const propNameToPath = propName => propName.split(".")

export const getProp = (result, propName) => {
	for (const key of propNameToPath(propName)) {
		if (result == null) {
			return result
		}
		result = result[key]
	}
	return result
}

export const normalizeTextForSearch = text => text.replace(/[^\S\r\n]+/g, " ").trim()

export const documentTypeForFile = (mimeType, fileName) => {
	const DOCUMENT_TYPE_BY_MIME_TYPE = {
		"application/pdf": "pdf",
		"application/vnd.ms-powerpoint": "ppt",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
		"application/vnd.openxmlformats-officedocument.presentationml.slideshow": "ppsx",
		"application/vnd.oasis.opendocument.presentation": "odp",
		"application/x-iwork-keynote-sffkey": "keynote",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
		"application/vnd.oasis.opendocument.text": "odt",
	}
	if (DOCUMENT_TYPE_BY_MIME_TYPE[mimeType] != null) {
		return DOCUMENT_TYPE_BY_MIME_TYPE[mimeType]
	}
	if (mimeType === "application/zip" && _.isString(fileName) && _.last(fileName.split(".")) === "key") {
		return "keynote"
	}
	return null
}

export const getMediaType = mimeType => {
	if (!_.isString(mimeType)) {
		return null
	}
	if (mimeType.startsWith("video/")) {
		return "video"
	}
	if (mimeType.startsWith("audio/")) {
		return "audio"
	}
	return null
}

export const NON_BROWSER_IMAGE_TYPES = ["image/heic", "image/heif", "image/tiff"]

export const getFileType = (mimeType, fileName) => {
	if (!_.isString(mimeType)) {
		return null
	}
	if (
		["image/jpeg", "image/png", "image/svg+xml", "image/gif", "image/webp", ...NON_BROWSER_IMAGE_TYPES].includes(
			mimeType
		)
	) {
		return "image"
	}
	if (["video/", "audio/"].some(prefix => mimeType.startsWith(prefix))) {
		return "media"
	}
	if (documentTypeForFile(mimeType, fileName) != null) {
		return "document"
	}
	return null
}

export const isDocSpeakerId = id => _.isString(id) && (id.startsWith("_") || id.charAt(20) === "_")

export const sanitizeName = s => {
	if (s == null) {
		return null
	}
	return String(s)
		.replace(/www\./g, "www_")
		.replace(/\:\/\//g, "___")
		.replace(/\</g, "")
		.replace(/>/g, "")
}

export const rowsToCsv = rows =>
	rows
		.map(row =>
			row
				.map(col => {
					if (col == null) {
						return ""
					}
					col = String(col)
					if (!/\s/.test(col) && !/"/.test(col) && !col.includes(",")) {
						return col
					}
					return `"${col.replace(/"/g, '""')}"`
				})
				.join(",")
		)
		.join("\n")

export const META_INFO_TYPES = ["text", "categorical", "boolean", "number", "date", "participant", "researcher"]

const META_INFO_TYPE_BY_FIRST_CHAR = _.fromPairs(META_INFO_TYPES.map(t => [t.charAt(0), t]))
export const fieldTypeFromNameIdOrNullIfInvalid = nameId => {
	const split = nameId.split("|")
	return META_INFO_TYPE_BY_FIRST_CHAR[split[1]?.charAt(0)]
}

export const fieldTypeFromNameId = nameId => {
	const type = fieldTypeFromNameIdOrNullIfInvalid(nameId)
	if (type == null) {
		throw Error(`Invalid nameId ${nameId}`)
	}
	return type
}

export const fieldTypeFromPropName = propName => {
	const META_INFOS_PREFIX = "metaInfos."
	if (!propName?.startsWith(META_INFOS_PREFIX)) {
		return null
	}
	return fieldTypeFromNameId(propName.substring(META_INFOS_PREFIX.length))
}

export const calcSearchScore = matchCounts => {
	if (matchCounts == null) {
		return []
	}
	let score = []
	for (const count of matchCounts) {
		const termsWordMatched = _.sumBy(count.word, r => (r > 0 ? 1 : 0))
		const termsAnywhereMatched = _.sumBy(count.anywhere, r => (r > 0 ? 1 : 0))
		const wordMatchedCount = _.sum(count.word)
		const anywhereMatchedCount = _.sum(count.anywhere)
		score = score.concat([termsWordMatched, termsAnywhereMatched, wordMatchedCount, anywhereMatchedCount])
	}
	return score
}

export const mergeInAdditionalMatchInfos = (infos1, infos2) => {
	if (infos1 == null && infos2 == null) {
		return null
	}
	infos1 = infos1 ?? {}
	infos2 = infos2 ?? {}
	const result = {}
	for (const key of _.uniq([...Object.keys(infos1), ...Object.keys(infos2)])) {
		let v1 = infos1[key]
		let v2 = infos2[key]
		if (_.isArray(v1) || _.isArray(v2)) {
			result[key] = _.uniq([...(v1 ?? []), ...(v2 ?? [])])
		} else if (_.isPlainObject(v1) || _.isPlainObject(v2)) {
			result[key] = { ...v1, ...v2 }
		} else if (v1 === v2) {
			result[key] = v1
		} else {
			console.error(`Cant merge additional match infos for key ${key}`)
		}
	}
	return result
}

export const RESEARCHER_ROLES = ["admin", "researcher"]
export const STAKEHOLDER_ROLES = ["full_access_stakeholder", "stakeholder"]
// The order here has to be from most to least privileges
export const VALID_ROLES = [...RESEARCHER_ROLES, ...STAKEHOLDER_ROLES]
export const VALID_FRONTEND_ONLY_ROLES = ["admin#nonresearch"]
export const VALID_FRONTEND_ROLES = [...VALID_FRONTEND_ONLY_ROLES, ...VALID_ROLES]

export const roleToRoleSpec = role => {
	if (!VALID_FRONTEND_ROLES.includes(role)) {
		return null
	}
	return {
		role: role.split("#")[0],
		...(VALID_FRONTEND_ONLY_ROLES.includes(role) ? { frontendRole: role } : {}),
	}
}

export const PLANS_WITH_STAKEHOLDER_ACCESS = [
	"trial",
	"enterprise_2020",
	"team_2020",
	"company_2021",
	"enterprise_2021",
	"team_2023",
	"business_2023",
	"enterprise_2023",
]

export const MAX_TRANSCRIPTION_SEC_IN_TRIAL = 2 * 60 * 60

export const DEFAULT_RESEARCH_SESSIONS_FIELDS = [
	{ name: "Date", type: "date", useCreateDate: true },
	{ name: "Participants", type: "participant" },
	{ name: "Researchers", type: "researcher", showResearchers: true },
]

export const DB_INTERNAL_COLUMNS = ["version", "resetVersion", "modifiedTimestamp", "createdTimestamp"]
export const ALWAYS_COLUMNS = ["id", "deleteVersion", ...DB_INTERNAL_COLUMNS]
export const COLUMNS_FOR_COLLECTION = {
	orgs: ["plan", ...ALWAYS_COLUMNS],
	workspaces: ["orgId", "name", ...ALWAYS_COLUMNS],
	projects: ["workspaceId", "name", "descriptionNoteId", "isTemplate", "metaInfos", ...ALWAYS_COLUMNS],
	notes: [
		"workspaceId",
		"type",
		"doc",
		"pmVersion",
		"projectId",
		"embeddedInProjectIds",
		"isPublished",
		"isShared",
		...ALWAYS_COLUMNS,
	],
	highlights: [
		"workspaceId",
		"tagIds",
		"tagGroupIds",
		"tagBoardIds",
		"projectId",
		"source",
		"observationSectionId",
		"observationSectionName",
		"observationSectionSortValue",
		"description",
		"mediaMapping",
		"participantIds",
		"researchSessionId",
		"researchSessionName",
		"usedInNoteIds",
		"usedInPublishedArtifact",
		"hasGlobalTag",
		"contentTypes",
		"virtualMediaFile",
		"shareSettings",
		"isShared",
		...ALWAYS_COLUMNS,
	],
	metaInfoFields: ["workspaceId", "nameId", "name", "group", "projectId", "type", ...ALWAYS_COLUMNS],
	participants: ["workspaceId", "name", "metaInfos", ...ALWAYS_COLUMNS],
	artifacts: [
		"workspaceId",
		"name",
		"embeddedArtifactIds",
		"deepEmbeddedArtifactIds",
		"embeddedInProjectIds",
		"usedInNoteIds",
		"type",
		"accessId",
		"isTemplate",
		"isPublished",
		"deepIsPublished",
		"isShared",
		"deepIsShared",
		"projectId",
		"metaInfos",
		...ALWAYS_COLUMNS,
	],
	researchSessions: ["workspaceId", "projectId", "name", "isTemplate", "metaInfos", ...ALWAYS_COLUMNS],
	observationSections: [
		"workspaceId",
		"researchSessionId",
		"fileId",
		"noteId",
		"name",
		"sortValue",
		"projectId",
		...ALWAYS_COLUMNS,
	],
	comments: [
		"workspaceId",
		"noteId",
		"isReferencedInText",
		"projectId",
		"isPublished",
		"isShared",
		"embeddedInProjectIds",
		...ALWAYS_COLUMNS,
	],
	notifications: [
		"workspaceId",
		"archived",
		"seen",
		"type",
		"userId",
		"referencingItemId",
		"pushHandled",
		...ALWAYS_COLUMNS,
	],
	files: [
		"workspaceId",
		"attachedToId",
		"attachedToProjectId",
		"name",
		"mimeType",
		"size",
		"usedInNoteIds",
		...ALWAYS_COLUMNS,
	],
	fileInfos: ["workspaceId", "text", ...ALWAYS_COLUMNS],
	tagBoards: ["workspaceId", "name", "description", ...ALWAYS_COLUMNS],
	tagGroups: ["workspaceId", "projectId", "tagBoardId", "name", "description", ...ALWAYS_COLUMNS],
	tags: [
		"workspaceId",
		"tagGroupId",
		"tagBoardId",
		"name",
		"description",
		"highlightsCount",
		"projectId",
		...ALWAYS_COLUMNS,
	],
	storedQueries: ["workspaceId", "collection", "noteId", "involvedCollections", "usedFor", ...ALWAYS_COLUMNS],
	users: ["orgId", "email", "role", "name", "workspaceIds", "groups", "authMethod", "active", ...ALWAYS_COLUMNS],
	userData: [...ALWAYS_COLUMNS],
}

export const COLLECTIONS_WITH_PROJECT_ID = Object.entries(COLUMNS_FOR_COLLECTION)
	.filter(i => i[1].includes("projectId"))
	.map(i => i[0])

export const WORKSPACE_COLLECTIONS = [
	...Object.entries(COLUMNS_FOR_COLLECTION)
		.filter(([__, columns]) => columns.includes("workspaceId"))
		.map(([collection]) => collection),
	"workspaces",
]
export const COLLECTIONS_WITH_ORG_ID = Object.entries(COLUMNS_FOR_COLLECTION)
	.filter(([__, columns]) => columns.includes("orgId"))
	.map(([collection]) => collection)

const NON_DATA_JSON_COLUMNS = ["metaInfos"]
// This is for all columns that should be handled as JSON and aren't "data". The reason why we treat it differently
// than "data" is because for the client, the "data" column doesn't exist
export const NON_DATA_JSON_COLUMNS_BY_COLLECTION = {
	..._.mapValues(COLUMNS_FOR_COLLECTION, v => _.intersection(v, NON_DATA_JSON_COLUMNS)),
	highlights: [
		"shareSettings",
		..._.intersection(_.intersection(COLUMNS_FOR_COLLECTION.highlights, NON_DATA_JSON_COLUMNS)),
	],
}

const workspaceCollectionsSet = new Set(WORKSPACE_COLLECTIONS)
export const isWorkspaceCollection = collection => workspaceCollectionsSet.has(collection)
export const hasWorkspaceIdColumn = collection => collection !== "workspaces" && workspaceCollectionsSet.has(collection)

export const mergeOpsToResetDynamicProperties = (item, collection) => {
	let columns = COLUMNS_FOR_COLLECTION[collection]
	if (columns == null) {
		console.error(
			`Unkown collection ${collection} for mergeOpsToResetDynamicProperties, using ALWAYS_COLUMNS as fallback`
		)
		columns = ALWAYS_COLUMNS
	}
	return _.fromPairs(Object.keys(item).flatMap(key => (!columns.includes(key) ? [[key, mergeOp("delete")]] : [])))
}
export const parseFormerUsers = formerUsers =>
	Object.entries(formerUsers ?? {}).map(([id, entry]) => ({
		role: "former_user",
		email: "",
		name: "Deleted user",
		...entry,
		isFormerUser: true,
		id,
	}))

export const chunkByWeight = (items, maxWeightInChunk, weightsOrWeightCb) => {
	if (items.length === 0) {
		return []
	}
	let weights = weightsOrWeightCb
	if (!Array.isArray(weightsOrWeightCb)) {
		weights = items.map(i => weightsOrWeightCb(i))
	}
	const itemsWithWeight = _.zip(items, weights)
	const result = [[]]
	let currentWeight = 0
	for (;;) {
		const [item, weight] = itemsWithWeight[0]
		const last = _.last(result)
		if (currentWeight + weight > maxWeightInChunk && last.length > 0) {
			result.push([])
			currentWeight = 0
			continue
		}
		itemsWithWeight.shift()
		_.last(result).push(item)
		currentWeight += weight
		if (itemsWithWeight.length === 0) {
			break
		}
	}
	return result
}

export const LANGUAGES = {
	"af-ZA": { name: "Afrikaans" },
	"sq-AL": { name: "Albanian" },
	"am-ET": { name: "Amharic" },
	"ar-EG": { name: "Arabic" },
	"hy-AM": { name: "Armenian" },
	"az-AZ": { name: "Azerbaijani" },
	"eu-ES": { name: "Basque" },
	"bn-IN": { name: "Bengali" },
	"bs-BA": { name: "Bosnian" },
	"bg-BG": { name: "Bulgarian" },
	"my-MM": { name: "Burmese" },
	"ca-ES": { name: "Catalan" },
	"zh-CN": { name: "Chinese", variant: "Mandarin, Simplified" },
	"zh-HK": { name: "Chinese", variant: "Cantonese, Traditional" },
	"zh-TW": { name: "Chinese", variant: "Mandarin, Traditional" },
	"yue-CN": { name: "Chinese", variant: "Cantonese, Simplified" },
	"hr-HR": { name: "Croatian" },
	"cs-CZ": { name: "Czech" },
	"da-DK": { name: "Danish" },
	"nl-NL": { name: "Dutch" },
	"nl-BE": { name: "Dutch (Belgium)" },
	"en-US": { name: "English", variant: "US" },
	"en-GB": { name: "English", variant: "United Kingdom" },
	"en-AU": { name: "English", variant: "Oceania" },
	"en-IN": { name: "English", variant: "India" },
	"en-NG": { name: "English", variant: "West Africa" },
	"en-KE": { name: "English", variant: "East Africa" },
	"en-ZA": { name: "English", variant: "South Africa" },
	"et-EE": { name: "Estonian" },
	"fil-PH": { name: "Filipino" },
	"fi-FI": { name: "Finnish" },
	"fr-FR": { name: "French" },
	"fr-BE": { name: "French (Belgium)" },
	"fr-CA": { name: "French (Canada)" },
	"gl-ES": { name: "Galician" },
	"ka-GE": { name: "Georgian" },
	"de-DE": { name: "German" },
	"de-CH": { name: "German", variant: "Switzerland" },
	"el-GR": { name: "Greek" },
	"gu-IN": { name: "Gujarati" },
	"he-IL": { name: "Hebrew" },
	"hi-IN": { name: "Hindi" },
	"hu-HU": { name: "Hungarian" },
	"is-IS": { name: "Icelandic" },
	"id-ID": { name: "Indonesian" },
	"ga-IE": { name: "Irish" },
	"it-IT": { name: "Italian" },
	"ja-JP": { name: "Japanese" },
	"jv-ID": { name: "Javanese" },
	"kn-IN": { name: "Kannada" },
	"kk-KZ": { name: "Kazakh" },
	"km-KH": { name: "Khmer" },
	"ko-KR": { name: "Korean" },
	"lo-LA": { name: "Lao" },
	"lv-LV": { name: "Latvian" },
	"lt-LT": { name: "Lithuanian" },
	"mk-MK": { name: "Macedonian" },
	"ms-MY": { name: "Malay" },
	"ml-IN": { name: "Malayalam" },
	"mt-MT": { name: "Maltese" },
	"mr-IN": { name: "Marathi" },
	"mn-MN": { name: "Mongolian" },
	"ne-NP": { name: "Nepali" },
	"nb-NO": { name: "Norwegian" },
	"ps-AF": { name: "Pashto" },
	"fa-IR": { name: "Persian" },
	"pl-PL": { name: "Polish" },
	"pt-BR": { name: "Portuguese", variant: "Brazil" },
	"pt-PT": { name: "Portuguese", variant: "Portugal" },
	"pa-IN": { name: "Punjabi" },
	"ro-RO": { name: "Romanian" },
	"ru-RU": { name: "Russian" },
	"sr-RS": { name: "Serbian" },
	"si-LK": { name: "Sinhala" },
	"sk-SK": { name: "Slovak" },
	"sl-SI": { name: "Slovenian" },
	"so-SO": { name: "Somali" },
	"es-MX": { name: "Spanish", variant: "Latin America" },
	"es-ES": { name: "Spanish", variant: "Spain" },
	"sw-KE": { name: "Swahili" },
	"sv-SE": { name: "Swedish" },
	"ta-IN": { name: "Tamil" },
	"te-IN": { name: "Telugu" },
	"th-TH": { name: "Thai" },
	"tr-TR": { name: "Turkish" },
	"uk-UA": { name: "Ukrainian" },
	"ur-IN": { name: "Urdu" },
	"uz-UZ": { name: "Uzbek" },
	"vi-VN": { name: "Vietnamese" },
	"cy-GB": { name: "Welsh" },
	"zu-ZA": { name: "Zulu" },
}

export const TRANSLATION_LANGUAGES = {
	af: "Arikaans",
	sq: "Albanian",
	am: "Amharic",
	ar: "Arabic",
	hy: "Armenian",
	az: "Azerbaijani",
	bn: "Bengali",
	bs: "Bosnian",
	bg: "Bulgarian",
	ca: "Catalan",
	zh: "Chinese (Simplified)",
	"zh-TW": "Chinese (Traditional)",
	hr: "Croatian",
	cs: "Czech",
	da: "Danish",
	"fa-AF": "Dari",
	nl: "Dutch",
	en: "English",
	et: "Estonian",
	fa: "Farsi (Persian)",
	tl: "Filipino, Tagalog",
	fi: "Finnish",
	fr: "French",
	"fr-CA": "French (Canada)",
	ka: "Georgian",
	de: "German",
	el: "Greek",
	gu: "Gujarati",
	ht: "Haitian Creole",
	ha: "Hausa",
	he: "Hebrew",
	hi: "Hindi",
	hu: "Hungarian",
	is: "Icelandic",
	id: "Indonesian",
	ga: "Irish",
	it: "Italian",
	ja: "Japanese",
	kn: "Kannada",
	kk: "Kazakh",
	ko: "Korean",
	lv: "Latvian",
	lt: "Lithuanian",
	mk: "Macedonian",
	ms: "Malay",
	ml: "Malayalam",
	mt: "Maltese",
	mr: "Marathi",
	mn: "Mongolian",
	no: "Norwegian (Bokmål)",
	ps: "Pashto",
	pl: "Polish",
	pt: "Portuguese (Brazil)",
	"pt-PT": "Portuguese (Portugal)",
	pa: "Punjabi",
	ro: "Romanian",
	ru: "Russian",
	sr: "Serbian",
	si: "Sinhala",
	sk: "Slovak",
	sl: "Slovenian",
	so: "Somali",
	es: "Spanish",
	"es-MX": "Spanish (Mexico)",
	sw: "Swahili",
	sv: "Swedish",
	ta: "Tamil",
	te: "Telugu",
	th: "Thai",
	tr: "Turkish",
	uk: "Ukrainian",
	ur: "Urdu",
	uz: "Uzbek",
	vi: "Vietnamese",
	cy: "Welsh",
}

/**
 * Intersection of LANGUAGES with the listed languages in the azure docs for Language identification:
 * https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=language-identification
 */
export const LANGUAGES_SUPPORTING_MULTI_LANGUAGE_TRANSCRIPTION = [
	"bn-IN",
	"bg-BG",
	"ca-ES",
	"zh-CN",
	"zh-HK",
	"zh-TW",
	"hr-HR",
	"cs-CZ",
	"da-DK",
	"nl-NL",
	"en-US",
	"en-GB",
	"en-AU",
	"en-IN",
	"en-NG",
	"en-KE",
	"en-ZA",
	"et-EE",
	"fi-FI",
	"fr-FR",
	"de-DE",
	"el-GR",
	"gu-IN",
	"he-IL",
	"hi-IN",
	"hu-HU",
	"id-ID",
	"ga-IE",
	"it-IT",
	"ja-JP",
	"kn-IN",
	"ml-IN",
	"ko-KR",
	"lv-LV",
	"lt-LT",
	"mt-MT",
	"mr-IN",
	"nb-NO",
	"pl-PL",
	"pt-BR",
	"pt-PT",
	"ro-RO",
	"ru-RU",
	"sk-SK",
	"sl-SI",
	"es-MX",
	"es-ES",
	"sv-SE",
	"ta-IN",
	"te-IN",
	"th-TH",
	"tr-TR",
	"uk-UA",
	"vi-VN",
]

export const UNPAID_INVOICES_GRACE_PERIOD_MS = 14 * 24 * 60 * 60 * 1000
export const TRIAL_DURATION_DAYS = 15

export const trialExpiresTimestamp = org => {
	const DAY_MS = 24 * 60 * 60 * 1000
	const trialDuration = org.trialDuration ?? TRIAL_DURATION_DAYS
	return (org.activated ?? Date.now()) + trialDuration * DAY_MS
}

export const isTrialExpired = (org, offsetMs = 0) => Date.now() + offsetMs > trialExpiresTimestamp(org)

export const isOrgPaused = org => {
	if (org.paused != null && org.paused < Date.now()) {
		return true
	}
	if (org.unpaidInvoicesSince != null && org.unpaidInvoicesSince + UNPAID_INVOICES_GRACE_PERIOD_MS < Date.now()) {
		return true
	}
	return false
}

const MAX_RECORDING_HOURS_FOR_TRANSCRIPTION = 4
export const isTooLongForTranscription = file =>
	(file.mediaInfo?.duration ?? 0) > MAX_RECORDING_HOURS_FOR_TRANSCRIPTION * 60 * 60
export const TOO_LONG_FOR_TRANSCRIPTION_MESSAGE = `The recording is too long to be transcribed, the limit is ${MAX_RECORDING_HOURS_FOR_TRANSCRIPTION} hours.`

export const toKeyFrameAlignedRange = (start, end, { duration = Infinity } = {}) => {
	const KEY_FRAME_DIST = 2
	const keyFrameStart = Math.max(0, Math.floor(start / KEY_FRAME_DIST) * KEY_FRAME_DIST)
	const keyFrameEnd = Math.min(
		duration,
		Math.max(keyFrameStart + KEY_FRAME_DIST, Math.ceil(end / KEY_FRAME_DIST) * KEY_FRAME_DIST)
	)
	return { start: keyFrameStart, end: keyFrameEnd }
}

export const projectIdsInRootFilter = rootFilter => {
	if (
		rootFilter != null &&
		rootFilter.category === "is_one_of" &&
		((rootFilter.collection === "projects" && rootFilter.specs.propName === "id") ||
			(COLLECTIONS_WITH_PROJECT_ID.includes(rootFilter.collection) && rootFilter.specs.propName === "projectId"))
	) {
		return rootFilter.specs.value
	}
	return null
}

const isLeadingSurrogate = codePoint => codePoint >= 0xd800 && codePoint <= 0xdbff
const isTrailingSurrogate = codePoint => codePoint >= 0xdc00 && codePoint <= 0xdfff

const indicesOfInvalidUtf8SurrogatesInString = inputString => {
	const wrongIndices = []
	let index = 0
	for (let c of inputString) {
		const len = c.length
		if (len === 1) {
			const codePoint = c.codePointAt(0)
			if (isLeadingSurrogate(codePoint) || isTrailingSurrogate(codePoint)) {
				wrongIndices.push(index)
			}
		}
		index += len
	}
	return wrongIndices
}

// this doesn't catch it if it's in the key of a plain object
export const hasInvalidUtf8Surrogates = value => {
	if (_.isString(value)) {
		return indicesOfInvalidUtf8SurrogatesInString(value).length > 0
	} else if (Array.isArray(value)) {
		return value.some(i => hasInvalidUtf8Surrogates(i))
	} else if (_.isPlainObject(value)) {
		return Object.values(value).some(v => hasInvalidUtf8Surrogates(v))
	} else {
		return false
	}
}

const replaceInvalidUtf8SurrogatesInString = inputString => {
	const indicesToFix = indicesOfInvalidUtf8SurrogatesInString(inputString)
	if (indicesToFix.length === 0) {
		return inputString
	}
	let result = ""
	let from = 0
	for (const index of indicesToFix) {
		result += inputString.slice(from, index) + "\uFFFD"
		from = index + 1
	}
	result += inputString.slice(from, inputString.length)
	return result
}

// this doesn't catch it if it's in the key of a plain object
export const replaceInvalidUtf8Surrogates = value => {
	if (_.isString(value)) {
		return replaceInvalidUtf8SurrogatesInString(value)
	} else if (Array.isArray(value)) {
		return value.map(i => replaceInvalidUtf8Surrogates(i))
	} else if (_.isPlainObject(value)) {
		return _.mapValues(value, v => replaceInvalidUtf8Surrogates(v))
	} else {
		return value
	}
}

export const authCookieNameWithPrefix = name => {
	const environment = process.env.CONDENS_ENVIRONMENT || process.env.VUE_APP_CONDENS_ENVIRONMENT
	let prefix = "condens_"
	if (environment && environment !== "production") {
		prefix = `${environment}_condens_`
	}
	return prefix + name
}

// based on https://github.com/jshttp/cookie/blob/84a156749b673dbfbf43679829b15be09fbd8988/index.js
const decodeCookieValue = value => (value.indexOf("%") !== -1 ? decodeURIComponent(value) : value)
export const parseCookie = str => {
	if (str == null) {
		return {}
	}
	if (!_.isString(str)) {
		throw new TypeError("str is not a string")
	}
	const result = {}
	let index = 0
	while (index < str.length) {
		let eqIndex = str.indexOf("=", index)
		if (eqIndex === -1) {
			break
		}
		let endIndex = str.indexOf(";", index)
		if (endIndex === -1) {
			endIndex = str.length
		} else if (endIndex < eqIndex) {
			index = str.lastIndexOf(";", eqIndex - 1) + 1
			continue
		}
		let key = str.slice(index, eqIndex).trim()
		if (undefined === result[key]) {
			let val = str.slice(eqIndex + 1, endIndex).trim()
			if (val.charCodeAt(0) === 0x22) {
				val = val.slice(1, -1)
			}
			result[key] = decodeCookieValue(val)
		}
		index = endIndex + 1
	}
	return result
}

export const maybePasswordNotComplexEnoughMessage = password => {
	const messages = []
	if (password.length < 10) {
		messages.push("has at least 10 characters")
	}
	if (!new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$").test(password)) {
		messages.push("contains lowercase, uppercase and a number")
	}
	if (messages.length === 0) {
		return null
	}
	const ALLOWED = "b274cc1b0f427ec3"
	if (hashString64(password) === ALLOWED) {
		return null
	}
	return `Makes sure the password ${messages.join(" and ")}.`
}

export const MAX_ITEMS_PER_IMPORT = 10_000
export const MAX_OBSERVATION_NOTES_PER_IMPORT = 20_000

export const SHARE_ID_LENGTH_WITHOUT_REGION = 21
export const SHARE_ID_REGEX_STRING = `[ah][a-zA-Z0-9]{20}${REGEX_SERVER_REGION_PART}`
export const ARTIFACT_SHARE_ID_REGEX_STRING = `a[a-zA-Z0-9]{20}${REGEX_SERVER_REGION_PART}`
export const HIGHLIGHT_SHARE_ID_REGEX_STRING = `h[a-zA-Z0-9]{20}${REGEX_SERVER_REGION_PART}`

export const SHARE_ID_PREFIX_BY_COLLECTION = {
	artifacts: "a",
	highlights: "h",
}
export const COLLECTION_BY_SHARE_ID_PREFIX = Object.fromEntries(
	Object.entries(SHARE_ID_PREFIX_BY_COLLECTION).map(e => e.reverse())
)

export const SHARE_ID_REGEX = new RegExp(`^${SHARE_ID_REGEX_STRING}$`)
export const collectionForShareId = id => {
	if (!SHARE_ID_REGEX.test(id)) {
		throw Error(`Invalid shareId "${id}"`)
	}
	const prefix = id.substring(0, 1)
	const collection = COLLECTION_BY_SHARE_ID_PREFIX[prefix]
	if (collection == null) {
		throw Error(`Wrong prefix ${prefix} for shareId`)
	}
	return collection
}

export const regionFromShareId = id => regionFromSingleChar(id?.charAt(SHARE_ID_LENGTH_WITHOUT_REGION))

export const safeParseJson = s => {
	if (!_.isString(s)) {
		return null
	}
	try {
		return JSON.parse(s)
	} catch (e) {}
	return null
}

export const createSpEntityId = (functionsBaseUrl, id) => functionsBaseUrl + "/sso/metadata/" + id

// because apparently it's slow in lodash https://github.com/lodash/lodash/issues/5401
export const areEqualSets = (set1, set2) => {
	if (set1 === set2) {
		return true
	}
	if (!(set1 instanceof Set) || !(set2 instanceof Set)) {
		return false
	}
	if (set1.size !== set2.size) {
		return false
	}
	for (const a of set1) {
		if (!set2.has(a)) {
			return false
		}
	}
	return true
}

export const DEFAULT_GLOBAL_TAG_BOARD_NAME = "Global Tags"

const indvidualFeatureSet = new Set(["googleDrive", "oneDrive", "zoom"])
const individual2021FeatureSet = new Set([
	...indvidualFeatureSet,
	"globalSearch",
	"globalTags",
	"participants",
	"projectTemplates",
])
const teamFeatureSet = new Set([...individual2021FeatureSet, "slack", "teams", "zapier", "stakeholderAccess"])
const businessFeatureSet = new Set([
	...teamFeatureSet,
	"granularPermissions",
	"usageReport",
	"artifactWorkshops",
	"enforce2fa",
	"sso",
])
const enterpriseFeatureSet = new Set([...businessFeatureSet, "automatedDataDeletion"])

// the order here matters for minPlanContainingFeature
export const FEATURE_SET_BY_PLAN = {
	individual_2023: indvidualFeatureSet,
	individual_2021: individual2021FeatureSet,
	team_2023: teamFeatureSet,
	company_2021: teamFeatureSet,
	team_2020: teamFeatureSet,
	business_2023: businessFeatureSet,
	enterprise_2023: enterpriseFeatureSet,
	enterprise_2021: enterpriseFeatureSet,
	enterprise_2020: enterpriseFeatureSet,
	trial: enterpriseFeatureSet,
}

const INDIVIDUAL_AUTO_CLUSTER = 30
const COMPANY_AUTO_CLUSTER = 60
const BUSINESS_AUTO_CLUSTER = 200
export const CONSTRAINTS_BY_PLAN = {
	individual_2023: { autoClusterHighlights: INDIVIDUAL_AUTO_CLUSTER, tagBoards: 0 },
	individual_2021: { autoClusterHighlights: INDIVIDUAL_AUTO_CLUSTER, tagBoards: 1 },
	team_2023: { autoClusterHighlights: COMPANY_AUTO_CLUSTER, tagBoards: 1 },
	company_2021: { autoClusterHighlights: COMPANY_AUTO_CLUSTER, tagBoards: 1 },
	team_2020: { autoClusterHighlights: COMPANY_AUTO_CLUSTER, tagBoards: 1 },
	business_2023: { autoClusterHighlights: BUSINESS_AUTO_CLUSTER, tagBoards: 3 },
	enterprise_2023: { autoClusterHighlights: Infinity, tagBoards: Infinity },
	enterprise_2021: { autoClusterHighlights: Infinity, tagBoards: Infinity },
	enterprise_2020: { autoClusterHighlights: Infinity, tagBoards: Infinity },
	trial: { autoClusterHighlights: Infinity, tagBoards: Infinity },
}

export const SSO_CERTIFICATE_WILL_EXPIRE_NOTIFICATION_DAYS = 30

export const BLOCK_ITEM_INNER_ID_LENGTH = 8
export const createBlockItemId = (noteId, innerId) => `${noteId}_${innerId}`
export const randomBlockItemId = noteId => createBlockItemId(noteId, randomFirestoreId(BLOCK_ITEM_INNER_ID_LENGTH))
export const noteIdFromBlockItemId = blockItemId => blockItemId?.split("_")[0]
export const extractBlockItemInnerId = blockItemId => {
	const noteIdLength = noteIdFromBlockItemId(blockItemId).length
	return blockItemId.substring(noteIdLength + 1, noteIdLength + 1 + BLOCK_ITEM_INNER_ID_LENGTH)
}

export const shareableUrlForShareId = (
	shareServerUrl,
	shareId,
	{ blockItemId = null, openBlockItemInModal = false } = {}
) => {
	if (openBlockItemInModal === true && blockItemId == null) {
		console.error(`openBlockItemInModal ignored because blockItemId not set`)
	}
	let url = `${shareServerUrl}/${shareId}`
	if (blockItemId != null) {
		url += `/${extractBlockItemInnerId(blockItemId)}`
		if (openBlockItemInModal === true) {
			url += "?m"
		}
	}
	return url
}

export const parseVideoFileThumbnailInfo = file => {
	const mediaInfo = file?.mediaInfo
	if (mediaInfo?.thumbnails == null) {
		return null
	}
	const intervalSeconds = (mediaInfo.thumbnails.frameInterval ?? 60) / 30
	let maxIndex = Math.max(0, Math.floor(mediaInfo.duration / intervalSeconds))
	if (mediaInfo.thumbnails.missingLastFrame === true) {
		maxIndex -= 1
	}
	return { maxIndex, intervalSeconds }
}

export const thumbnailIndexForTime = (file, time) => {
	const info = parseVideoFileThumbnailInfo(file)
	if (info == null) {
		return null
	}
	return Math.min(info.maxIndex, Math.ceil(time / info.intervalSeconds))
}

export const getFromLocalStorage = (key, fallback = null) => {
	if (!isBrowser || !_.isFunction(window.localStorage?.getItem)) {
		return fallback
	}
	return window.localStorage.getItem(key) ?? fallback
}

export const saveToLocalStorage = (key, value) => {
	if (isBrowser && _.isFunction(window.localStorage?.setItem)) {
		window.localStorage.setItem(key, value)
	}
}

export const removeFromLocalStorage = key => {
	if (isBrowser && _.isFunction(window.localStorage?.removeItem)) {
		window.localStorage.removeItem(key)
	}
}

// this is returns an ordered array that can be directly passed to context.drawImage(image, ...resultOfThisMethod)
export const calcDrawImageOnCanvasParams = ({ width, height, objectFit, image, objectPosition, drawnImageArea }) => {
	if (width == null || height == null || (objectFit === "fill" && drawnImageArea == null)) {
		return null
	}
	let drawImageParams = {
		sourceX: 0,
		sourceY: 0,
		sourceWidth: image.width,
		sourceHeight: image.height,
		targetX: 0,
		targetY: 0,
		targetWidth: width,
		targetHeight: height,
	}
	if (drawnImageArea != null) {
		const sourceWidth = image.width * drawnImageArea.width
		drawImageParams = {
			...drawImageParams,
			sourceX: image.width * drawnImageArea.x,
			sourceY: image.height * drawnImageArea.y,
			sourceWidth,
			sourceHeight: sourceWidth * (height / width),
		}
	} else {
		objectPosition = { x: 0.5, y: 0.5, ...objectPosition }
		const targetRatio = width / height
		const imageRatio = image.width / image.height
		if (objectFit === "contain") {
			if (targetRatio > imageRatio) {
				const scale = height / image.height
				const targetWidth = image.width * scale
				drawImageParams = { ...drawImageParams, targetX: (width - targetWidth) * objectPosition.x, targetWidth }
			} else {
				const scale = width / image.width
				const targetHeight = image.height * scale
				drawImageParams = { ...drawImageParams, targetY: (height - targetHeight) * objectPosition.y, targetHeight }
			}
		} else {
			let sourceX = 0
			let sourceWidth = image.width
			let sourceHeight = image.width / targetRatio
			let sourceY = (image.height - sourceHeight) * objectPosition.y
			if (sourceY < 0) {
				sourceY = 0
				sourceHeight = image.height
				sourceWidth = targetRatio * image.height
				sourceX = (image.width - sourceWidth) * objectPosition.x
			}
			drawImageParams = { ...drawImageParams, sourceX, sourceY, sourceWidth, sourceHeight }
		}
	}
	return [
		drawImageParams.sourceX,
		drawImageParams.sourceY,
		drawImageParams.sourceWidth,
		drawImageParams.sourceHeight,
		drawImageParams.targetX,
		drawImageParams.targetY,
		drawImageParams.targetWidth,
		drawImageParams.targetHeight,
	]
}

export const ensureValidCoverImagePosition = position => _.clamp(position ?? 0.5, 0, 1)

export const canvasImagePropsForCoverImage = (coverImage, file) => {
	if (file.importInfo?.source === "avatar") {
		const drawnImageArea = { x: 0.08, y: 0.14, width: 0.5 }
		return { drawnImageArea }
	}
	return {
		objectPosition: {
			x: ensureValidCoverImagePosition(coverImage.config?.positionX),
			y: ensureValidCoverImagePosition(coverImage.config?.positionY),
		},
		objectFit: coverImage.config?.fit ?? "cover",
	}
}

export const rangesIntersect = (x1, x2, y1, y2) => x1 <= y2 && y1 <= x2
export const rangesInnerIntersect = (x1, x2, y1, y2) => x1 < y2 && y1 < x2

export const maybeChangeDeletedTo = item => {
	if (!item.hasOwnProperty("deleteVersion")) {
		return null
	}
	if (item.deleteVersion != null) {
		return true
	} else {
		return false
	}
}

export const RES_IMAGE_LARGE_SIZE = 2 * 848

export const MAX_ITEMS_SMART_CLUSTER = 500

export const INITIAL_USER_DATA = { showOnboarding: true, showFirstSteps: true, hints: ["dragHighlight"] }
